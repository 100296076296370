import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment/moment';
import { ApiClientConstant, ApiConnector, Table } from 'api-client';
import { ConnectionService } from '../../../../services/connection-service';
import { AppConfig } from '../../../app.config';
import { LocalStorage } from '../../../../services/local-storage-service';
import { InputType } from '../../../../../typings/client/input';
import { Broadcaster } from '../../../../components/broadcaster';
import { RegimenProductChangeHistoryComponent } from './regimen-product-change-history-model/regimen-product-change-history.component';
import { WindowRefService } from '../../../../services/window-ref-service';
import { ValueOf } from '../../../../../typings/server/common';

declare interface FollowUpSop {
  name: string;
  shortName: string;
  tests: { name?: string, tests?: Array<string> };
  disabled: boolean;
  changes: Array< { type: string, position?: number, partOfDay: string, instruction?: any, productId: string }>;
  nextFollowUps: Array<FollowUpSop>;
  voiceNote: { en?: string, hi?: string, kn?: string, te?: string, ta?: string, ma?: string, mr?: string, bn?: string };
  treatmentProgress: Array<{ issue: string, concernLanguageString: any, score: number}>;
}

@Component({ selector: 'regimen-edit', templateUrl: './regimen-edit.html', styleUrls: ['./regimen-edit.scss'] })
export class RegimenEditComponent implements OnDestroy {
  followUpSop: Array<FollowUpSop> = [];
  autoCompleteProductController: UntypedFormControl = new UntypedFormControl();
  autoCompleteRegimenTagsController: UntypedFormControl = new UntypedFormControl();
  productOptions: Observable<Array<{ name: string; object: any }>>;
  orderConfirmationMessage: string = '';
  userType: string = '';
  regimenObj: any = {};
  actionLog: Array<any> = [];
  regimenClasses: Array<InputType.SelectOption>;
  copyFromRegimenId: string;
  userRoles: Array<string> = [];
  followUpProducts: Array<any> = [];
  urlSplit: Array<string> = [];
  ui: any;
  reasonsToEdit: Array<{ display: string, value: string }> = [];
  defaultReasonsToEdit: Array<{ display: string, value: string }> = [
    { display: 'Default regimenSop Selection', value: 'REGIMEN_SOP_SELECTION' },
    { display: 'Repair Product combination needed not available', value: 'REPAIR_PRODUCT_COMBINATION_NEEDED_NOT_AVAILABLE' },
    { display: 'Incorrect Instructions', value: 'INCORRECT_INSTRUCTIONS' },
    { display: 'Incorrect Facewash', value: 'INCORRECT_FACEWASH' },
    { display: 'Incorrect sunscreen', value: 'INCORRECT_SUNSCREEN' },
    { display: 'Incorrect or missing moisturiser', value: 'INCORRECT_OR_MISSING_MOISTURISER' },
    { display: 'Display Concern Updated', value: 'DISPLAY_CONCERN_UPDATED' },
    { display: 'Other', value: 'OTHER' },
  ];
  editReason: any;
  editDetails: any;
  totalPrice: number = 0;
  priceBeforeEditing: number = 0;
  subscriptions: Array<Subscription>;
  services: Array<InputType.SelectOption> = [];
  dietAndLifestyleList: Array<InputType.SelectOption> = [];
  disableVariant: boolean;
  paymentTypeList: Array<InputType.SelectOption> = [
    { value: ApiClientConstant.Order.PaymentType.COD, display: 'COD' },
    { value: ApiClientConstant.Order.PaymentType.ONLINE, display: 'ONLINE' },
    { value: ApiClientConstant.Order.PaymentType.NA, display: 'NA' },
  ];
  regimenChange: any = {};
  hideHeader: boolean;
  fromOrderApproval: boolean;
  saveOnProcess: boolean;
  username: string;
  editType: string;
  disableButton: boolean;
  approvalPercentageOption: Array<InputType.SelectOption> = [];
  followUpConfig: any = {
    intervalInDays: 28,
    durationInDays: 90,
  };
  mediaLinkObj:any;
  regimenTagOptions: Observable<Array<{ name: string; object: any }>>;
  window: any;
  updateEditReasonRegimenSop: boolean = false;

  // variant changes
  variants: Array<any> = [
    { followUpConfig: this.followUpConfig, variantId: this.appConfig.Shared.Regimen.Variants.VariantId.variant_1499 },
    { followUpConfig: this.followUpConfig,
      variantId: this.appConfig.Shared.Regimen.Variants.VariantId.variant_1999,
      approvalPercentage: 100,
      fixedPrice: 2000,
      fixedPriceMRP: 2450,
      numberOfProductsLimit: 5 },
    { followUpConfig: this.followUpConfig,
      variantId: this.appConfig.Shared.Regimen.Variants.VariantId.variant_4999,
      approvalPercentage: 100,
      fixedPrice: 3000,
      fixedPriceMRP: 3600,
      numberOfProductsLimit: 5 },
    { followUpConfig: this.followUpConfig,
      variantId: this.appConfig.Shared.Regimen.Variants.VariantId.variant_999,
      approvalPercentage: 0,
      fixedPrice: 999,
      fixedPriceMRP: 1250,
      numberOfProductsLimit: 3 },
  ];
  currentVariant: ValueOf<typeof this.appConfig.Shared.Regimen.Variants.VariantId>;
  regimenObjVariant1: any;
  regimenObjVariant2: any;
  regimenObjVariant3: any;
  regimenObjVariant4: any;
  isProductUpdated:boolean = false;
  regimenBeforeChangeProducts:any = [];
  changeMorningProducts: boolean = false;
  changeNightProducts: boolean = false;
  orders: any = [];
  isProductExistedWarningIgnored: boolean = false;
  changedProductsWhichAreAlreadyInOrdersInTransit: any = [];
  changedProductsWhichAreAlreadyInOrdersInDelivered: any = [];
  antibioticsReasonRequired: boolean = false;
  selectedChatType: 'userChat' | 'chatSummary' = 'chatSummary';
  showAsOfflineRegimen: boolean = false;
  @Output('afterSaveRegimen') afterSaveRegimen: EventEmitter<any> = new EventEmitter();
  @Output('afterChangeProduct') afterChangeProduct: EventEmitter<any> = new EventEmitter();
  @Input('hideNotNecessaryThings') hideNotNecessaryThings: boolean = false;
  @Input('regimen')
  set processRegimen(regimen: any) {
    if (!regimen) return;
    this.hideHeader = true;
    this.fromOrderApproval = true;
    this.updateRegimen(regimen);
  }
  private originalProductIds: Array<string> = [];
  @Input('editType')
  set processEditReasons(editType: string) {
    this.editType = editType;
    this.initReasons();
  }
  capturedByDoctor: any;
  currentUser: any;
  canDoctorApproveRegimen: boolean = true;
  patientName: string;
  constructor(private router: Router,
    private conn: ConnectionService,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private storage: LocalStorage,
    private broadcast: Broadcaster,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog, windowRef: WindowRefService,
    private broadcaster: Broadcaster,
  ) {
    this.window = windowRef.nativeWindow;
  }
  addFollowUp(): any {
    this.followUpSop.push({
      name: '',
      shortName: '',
      tests: {},
      disabled: false,
      changes: [],
      nextFollowUps: [],
      voiceNote: {},
      treatmentProgress: [],
    });
  }

  async ngOnInit(): Promise<any> {
    this.mediaLinkObj = new Table.MediaLink();
    this.currentUser = this.conn.getCurrentUser();
    this.broadcaster.broadcast('ChatUserUpdate', { user: this.currentUser });
    this.username = this.currentUser.get('username');
    this.userType = this.currentUser.get('type');
    this.approvalPercentageOption = [
      { display: 0, value: 0 },
      { display: 10, value: 10 },
      { display: 20, value: 20 },
      { display: 30, value: 30 },
      { display: 40, value: 40 },
      { display: 50, value: 50 },
      { display: 60, value: 60 },
      { display: 70, value: 70 },
      { display: 80, value: 80 },
      { display: 90, value: 90 },
      { display: 100, value: 100 },
    ];
    this.regimenTagOptions = this.autoCompleteRegimenTagsController.valueChanges
      .pipe(
        debounceTime(300),
        filter((token: string) => !!token.length),
        mergeMap((token: string) => this.getLanguageStringTags(token)));
    this.productOptions = this.autoCompleteProductController.valueChanges.pipe(
      debounceTime(300),
      filter((token: string) => !!token.length),
      mergeMap((token: string) => this.getProducts(token)));
    this.initReasons();
    this.subscriptions = [];
    this.ui = { addProductFlag: false, makeBlur: false };
    this.reset();
    this.regimenClasses = Object.keys(ApiClientConstant.Regimen.Class)
      .map((key: string) => ({ display: key, value: key }));
    this.userRoles = this.storage.getJsonValue('userRoles');
    this.conn.getServices({
      type: ApiClientConstant.Service.Type.REGIMEN_CONSULTATION,
      ascending: 'createdAt',
    })
      .then((data: any) => (this.services = data.map((service: any): InputType.SelectOption => ({
        display: `${service.get('duration')} &nbsp; weeks &nbsp; ${service.get('amount')} Rupees &nbsp;&nbsp;`,
        value: service.id,
        parseValue: service,
      }))));
    await this.subscribeToUrlQueryParams();
    this.fetchDietAndLifestyles();
    this.route.queryParamMap.subscribe((queryParams: any): void => {
      const type = queryParams.get('type');
      this.showAsOfflineRegimen = type === 'offline';
      this.hideNotNecessaryThings = type === 'offline';
    });
    if (this.route.parent.snapshot.data.regimen) {
      this.setRegimenBeforeChangeProducts();
    }
    this.canDoctorApproveRegimen = this.checkDoctorCanApproveRegimen();
  }

  checkDoctorCanApproveRegimen(): boolean {
    if (this.currentUser.id !== this.capturedByDoctor.id) {
      this.broadcaster.broadcast('NOTIFY', {
        message: 'Regimen already captured by another doctor',
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
      return false;
    }
    return true;
  }

  getProductDisplayName(product: any): string {
    return `${product.get('title')} [ ${
      (product.get('margUnit') || 1) > 1
        ? `${product.get('margUnit')} sheets, `
        : ''
    }${product.get('quantity')}${product.get('quantityUnit')} ] ${product.get('type') === 'sample' ? '(sample)' : ''}`;
  }

  setRegimenBeforeChangeProducts():void {
    this.regimenBeforeChangeProducts
      .push([...this.route.parent.snapshot.data.regimen.get('morning').map((product:any) => JSON.parse(JSON.stringify(product))),
        ...this.route.parent.snapshot.data.regimen.get('night').map((product:any) => JSON.parse(JSON.stringify(product)))]);
  }

  openNewTab(url: string): void {
    this.window.open(url, '_blank');
  }
  async getProducts(name: string): Promise<Array<{ name: string; object: any }>> {
    const products = await this.conn.findCatalogs({
      where: { title: { $regex: name, $options: 'i' } },
      limit: 20,
      project: ['title', 'quantity', 'quantityUnit', 'type', 'isRepair'],
    });
    return products.map((product: any): { name: string; object: any } => ({
      name: `${product.get('title')} [${product.get('quantity')}${product.get('quantityUnit')}] (${product.get('type')})`,
      object: product,
    }));
  }
  async getLanguageStringTags(name: string): Promise<Array<{ name: string; object: any }>> {
    const languageStrings = await this.conn.findLanguageStringTags({
      where: { name: { $regex: name, $options: 'i' } },
      limit: 5,
      project: ['name'],
    });
    return languageStrings.map((languageString: any): { name: string; object: any } => ({
      name: languageString.get('name'),
      object: languageString,
    }));
  }

  autoCompleteProductOnSelect(item: { name: string; object: any }): void {
    this.followUpProducts.push(item.object);
    this.autoCompleteProductController.setValue('');
  }

  removeProduct(index: number): void {
    this.followUpProducts.splice(index, 1);
  }

  initReasons(): void {
    if (this.editType === AppConfig.Shared.RegimenSop.RegimenSop) {
      this.reasonsToEdit = this.defaultReasonsToEdit;
      return;
    }
    this.reasonsToEdit = [
      { display: 'Progress unsatisfactory: Changing/adding repair products', value: 'PROGRESS_UNSATISFACTORY_ADDING_REPAIR_PRODUCTS' },
      { display: 'Progress unsatisfactory: Changing/adding oral medications', value: 'PROGRESS_UNSATISFACTORY_ADDING_ORAL_MEDICATIONS' },
      { display: 'Progress Satisfactory: Moving to next concern (display concern updated)',
        value: 'PROGRESS_SATISFACTORY_MOVING_TO_NEXT_CONCERN' },
      { display: 'Different concern', value: 'DIFFERENT_CONCERN' },
      { display: 'Progress satisfactory: Moving to maintenance', value: 'PROGRESS_SATISFACTORY_MAINTENANCE' },
      { display: 'Progress satisfactory: Moving to milder regimen', value: 'PROGRESS_SATISFACTORY_MILDER' },
      { display: 'User complaints: Moving to milder variant', value: 'USER_COMPLAINT_MILDER' },
      { display: 'Continue Maintenance', value: 'CONTINUE_MAINTENANCE' },
      { display: 'Other', value: 'OTHER' },
      { display: 'Instruction correction/change.', value: 'INSTRUCTION_CORRECTION' },
      { display: 'Additional concerns addressed', value: 'ADDITIONAL_CONCERNS_ADDRESSED' },
      { display: 'Add on product added', value: 'ADD_ON_PRODUCT_ADDED' },
    ];
  }
  async submit(): Promise<any> {
    this.regimenObj.set('followUpSop', this.followUpSop);
  }

  subscribeToUrlQueryParams(): void {
    this.subscriptions.push(this.route.queryParams.subscribe((queryParams: Params) => {
      if (queryParams.copy) {
        this.conn.getRegimen(queryParams.copy)
          .then((regimen: any) => {
            this.copyFromRegimen(regimen, true);
            this.updateRegimen(this.regimenObj);
          });
      }
    }));
    this.subscriptions.push(this.route.parent.params.subscribe(async () => {
      if (this.route.parent.snapshot.data.alternateRegimen) {
        this.updateRegimen(this.route.parent.snapshot.data.alternateRegimen);
      } else if (this.route.parent.snapshot.data.regimen) {
        if (this.isAlternateRegimen()) {
          this.copyFromRegimen(this.route.parent.snapshot.data.regimen, true);
          this.regimenObj.set('regimenId', this.route.parent.snapshot.data.regimen.get('regimenId'));
          this.regimenObj.set('class', this.route.parent.snapshot.data.regimen.get('class'));
          this.updateRegimen(this.regimenObj);
          return;
        }
        this.updateRegimen(this.route.parent.snapshot.data.regimen);
      }
    }));
  }

  async setRegimenCoverImage(products: Array<any>, updateCoverImage: boolean = false): Promise<void> {
    const combinedProductIds: string = this.getCombinedProductIds(products);
    this.mediaLinkObj = await ApiConnector.findOne(Table.MediaLink,
      {
        where: { uniqueId: combinedProductIds },
        project: ['objectId', 'link'],
      });
    if (this.mediaLinkObj) {
      if (updateCoverImage) {
        this.regimenObj.set('regimenCoverImage', this.mediaLinkObj.get('link'));
      }
      this.openNewTab(`${this.conn.getBaseUrl()}/products/medialink/${this.mediaLinkObj?.id}/edit`);
      return;
    }
    this.openNewTab(`${this.conn.getBaseUrl()}/products/medialink/new`);
  }

  async getImageUrlforCombinedProduct(combineProductId: string): Promise<string> {
    if (!combineProductId) return '';
    const [mediaLink]: any = await this.conn.getMediaLink({ where: { uniqueId: combineProductId } });
    if (!mediaLink) return '';
    return mediaLink.get('link');
  }

  getCombinedProductIds(products: Array<any>): string {
    if (!products?.length) return '';
    const productIds = products.map((product: any) => product.get('mainProduct')?.id);
    return `regimenCoverPhoto_${productIds.sort().join('_')}`;
  }

  async openMediaLinkEditForCurrentProducts(): Promise<void> {
    if (!this.currentVariant) {
      await this.setRegimenCoverImage(this.regimenObj.get('products'));
      return;
    }
    const products = this.getAllUniqueProducts([...this.regimenObj.get('morning'), ...this.regimenObj.get('night')]);
    await this.setRegimenCoverImage(products, true);
  }

  updateRegimen(regimen: any): void {
    this.capturedByDoctor = regimen.get('capturedByDoctor');
    this.regimenObj = regimen;
    if (!this.regimenObj.has('approvalPercentage')) {
      if (this.regimenObj.get('skipDrApproval')) {
        this.regimenObj.set('approvalPercentage', 0);
      } else {
        this.regimenObj.set('approvalPercentage', 100);
      }
    }
    if (this.regimenObj.has('followUpConfig')) {
      this.followUpConfig = this.regimenObj.get('followUpConfig');
    }
    this.followUpSop = this.regimenObj.get('followUpSop') || [];
    this.regimenChange.concernsLanguageString = this.regimenObj?.get('concernsLanguageString');
    this.regimenChange.originalProducts = [...this.regimenObj.get('morning'), ...this.regimenObj.get('night')];
    this.originalProductIds = [
      ...this.regimenObj.get('morning').map((each: any) => each.product.id),
      ...this.regimenObj.get('night').map((each: any) => each.product.id),
    ];
    this.updateVariantsDetails();
    this.findRegimenChangeLog();
    this.updateRegimenType();
    this.updatePrice();
    this.priceBeforeEditing = this.totalPrice;
    this.patientName = this.regimenObj?.get('forUser')?.get('PatientName');
    delete this.regimenObj;
    setTimeout(() => (this.regimenObj = regimen), 0);
  }

  async onProductUpdate(): Promise<void> {
    this.afterChangeProduct.emit('regimenEdited');
    this.disableFollowUpSop();
    this.updatePrice();
    this.isProductUpdated = true;
  }

  disableFollowUpSop(): void {
    if (this.regimenObj.get('type') === this.appConfig.Shared.Regimen.Type.MAIN) return;
    if (this.regimenObj.dirtyKeys().includes('morning') || this.regimenObj.dirtyKeys().includes('night')) {
      this.regimenObj.set('disableFollowUpSop', true);
    }
  }
  updatePrice(): void {
    const morning = this.regimenObj.get('morning');
    const night = this.regimenObj.get('night');
    const morningNight = [].concat(morning || []).concat(night || []);
    this.totalPrice = 0;
    const uniqueProducts = [];
    morningNight.forEach((item: any) => {
      if (!item.product || uniqueProducts.includes(item.product.id)) {
        return;
      }
      this.totalPrice += item.product.get('price');
      uniqueProducts.push(item.product.id);
    });
    if (this.regimenObj.get('consultationService')) {
      this.totalPrice += this.regimenObj.get('consultationService').get('amount');
    }
  }

  updateRegimenEditReason(): void {
    this.editReason = '';
    if (this.editType === this.appConfig.Shared.RegimenSop.RegimenSop && !this.updateEditReasonRegimenSop) {
      this.reasonsToEdit = this.defaultReasonsToEdit.splice(1);
      this.updateEditReasonRegimenSop = true;
    }
  }

  findRegimenChangeLog(): void {
    if (!this.regimenObj.id) return;
    this.conn.getActionLog({ regimen: this.regimenObj })
      .then((actionLog: Array<any>) => (this.actionLog = actionLog));
  }

  reset(): void {
    this.regimenObj = new Table.Regimen();
    this.regimenObj.set('morning', []);
    this.regimenObj.set('night', []);
    this.updateRegimenType();
  }

  updateRegimenType(): void {
    if (this.regimenObj.get('type')) return;
    if (this.isAlternateRegimen()) {
      this.regimenObj.set('type', ApiClientConstant.Regimen.Type.ALTERNATE);
      return;
    }
    this.regimenObj.set('type', ApiClientConstant.Regimen.Type.MAIN);
  }

  isAlternateRegimen(): boolean {
    this.urlSplit = this.router.url.split('/');
    if (this.urlSplit[this.urlSplit.length - 2] === 'alternates') return true;
    return this.urlSplit[this.urlSplit.length - 3] === 'alternate';
  }

  async saveRegimen(context?: any): Promise<any> {
    this.disableVariant = false;
    const variantsUpdated = await this.updateVariants();
    if (!variantsUpdated) {
      return;
    }
    if (this.disableButton) {
      this.broadcast.broadcast('NOTIFY', { message: 'after approval regimen cannot be updated',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    if (this.followUpSop) {
      this.regimenObj.set('followUpSop', this.followUpSop);
    }
    const isEditAllowed = await this.checkIfRegimenEditAllowed();
    if (!isEditAllowed) {
      this.saveOnProcess = false;
      return;
    }
    if (!this.userRoles.includes('regimenEditor')) {
      alert('Regimen Edit permission is missing.');
      return;
    }
    this.saveOnProcess = true;
    const isNew = !!this.regimenObj.id;
    const reason = {
      editReason: this.editReason,
      editDetails: this.editDetails,
    };
    let valid;
    this.regimenObj.set('regimenEditReason', reason);
    const result = await this.isWhenToUseHavingSameDay();
    if (valid) {
      this.saveOnProcess = false;
      return;
    }
    if (!this.editReason) {
      this.saveOnProcess = false;
      this.broadcast.broadcast('NOTIFY', { message: 'Regimen Edit Reason Should not be blank',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return;
    }
    const repairProductCheck = await this.repairShouldNotBeInMorning();
    if (!result || !repairProductCheck) return;
    const changeInRegimen = [...this.regimenObj.get('morning'), ...this.regimenObj.get('night')]
      .some((each: any) => !this.originalProductIds.includes(each.product.id));
    if (changeInRegimen) {
      valid = await this.isRepairProductEditedButDisplayConcernNotChanged();
      if (!valid) {
        this.saveOnProcess = false;
        return;
      }
    }
    if (this.antibioticsReasonRequired && this.regimenObj?.get('continueAntibioticsReason')?.length < 15) {
      this.broadcast.broadcast('NOTIFY', { message: 'For continue antibiotics give reason in more than 15 characters',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      this.saveOnProcess = false;
      return;
    }
    this.removeUpdatedFlag();
    if (this.regimenObj.get('approvalPercentage')) {
      this.regimenObj.set('approvalPercentage', Number(this.regimenObj.get('approvalPercentage')));
    }
    if (this.regimenObj.get('numberOfProductsLimit') && !isNaN(this.regimenObj.get('numberOfProductsLimit'))) {
      this.regimenObj.set('numberOfProductsLimit', this.regimenObj.get('numberOfProductsLimit'));
    }
    this.regimenObj.set('followUpConfig', this.followUpConfig);
    if (this.isProductUpdated && !this.isProductExistedWarningIgnored && this.regimenObj?.id && this.regimenObj.has('forUser')) {
      await this.checkChangedProductsHasAlreadyInOrder();
      if (this.changedProductsWhichAreAlreadyInOrdersInDelivered?.length
        || this.changedProductsWhichAreAlreadyInOrdersInTransit?.length) {
        return;
      }
    }
    try {
      await this.regimenObj.save({}, { context });
      this.saveOnProcess = false;
      this.antibioticsReasonRequired = false;
      this.broadcast.broadcast('NOTIFY', { message: 'Regimen Updated', type: this.appConfig.Shared.Toast.Type.SUCCESS });
      if (this.fromOrderApproval) {
        this.afterSaveRegimen.emit(this.regimenObj);
        return;
      }
      if (this.isAlternateRegimen()) {
        this.router.navigate(
          [`${isNew ? '../' : ''}../../alternate`, this.regimenObj.get('regimenId')],
          { relativeTo: this.route });
        return;
      }
      this.router.navigate(
        [`${isNew ? '../' : ''}../../regimen`, this.regimenObj.get('regimenId')],
        { relativeTo: this.route });
    } catch (err: any) {
      this.saveOnProcess = false;
      if (err.code === 422) {
        if (err.message === 'Not more than two months antibiotics allowed in regimen') {
          if (confirm('Avoid antibiotics for over 2 months. If needed, provide a reason.')) {
            this.antibioticsReasonRequired = true;
            // eslint-disable-next-line consistent-return
            return err;
          }
        }
        if (err.message === 'Not more than two repair products allowed') {
          this.broadcast.broadcast('NOTIFY',
            { message: 'More Than Two Repair Products Not Allowed', type: this.appConfig.Shared.Toast.Type.ERROR });
          return;
        }
        if (err.message.products) {
          this.followUpProducts = await this.conn
            .getProducts({ where: { objectId: err.message.products.map((each: any) => each.id) } });
        }
        this.regimenObj.set('followUpNeeded', true);
        this.regimenObj.set('followUpRequired', true);
        this.orderConfirmationMessage = err.message.message;
        await this.showDialogForCreateOrderConfirmation();
        // eslint-disable-next-line consistent-return
        return err;
      }
      alert(err.message.message || err.message);
      // eslint-disable-next-line consistent-return
      return err;
    }
  }

  async productExistedInPreviousOrder(): Promise<void> {
    this.isProductExistedWarningIgnored = true;
    await this.saveRegimen();
  }

  checkWhichProductChanged():void {
    const regimenMorningProduct = this.regimenObj.get('morning').map((product:any) => product.product.get('margId'));
    const regimenNightProduct = this.regimenObj.get('night').map((product:any) => product.product.get('margId'));
    const editedRegimenAllProductIds:any = [...regimenMorningProduct, ...regimenNightProduct];
    const oldRegimenAllProductIds:any = this.regimenBeforeChangeProducts[0].map((product:any) => product.product.margId);
    return editedRegimenAllProductIds.filter((item:any) => !oldRegimenAllProductIds.includes(item));
  }

  resetPopUp():void {
    this.changedProductsWhichAreAlreadyInOrdersInTransit.length = 0;
    this.changedProductsWhichAreAlreadyInOrdersInDelivered.length = 0;
    this.window.location.reload();
  }

  async checkChangedProductsHasAlreadyInOrder(): Promise<void> {
    try {
      const localProductMap = new Map();
      const startDate = moment().subtract(15, 'day').startOf('day').add(6, 'hours')
        .toDate();
      const endDate = moment().startOf('day').add(1, 'day').toDate();
      const where: any = {};
      where.user = this.regimenObj.get('forUser');
      where.createdAt = { $gte: startDate, $lte: endDate };
      where.isPartialOrder = false;
      where.stage = { $nin: ['RETURN_INITIATED',
        'RETURN_REQUESTED',
        'ONLINE_PAYMENT_PENDING',
        'ONLINE_PAYMENT_SUCCESS',
        'INITIAL',
        'RETURNED',
        'CANCELED',
        'LOST_IN_TRANSIT'] };
      this.orders = await this.conn.getOrders({ where });
      const productIds:any = this.checkWhichProductChanged();
      await Promise.all(this.orders.map(async (order:any) => {
        await this.updateIfProductsHasAlreadyInOrder(order, productIds, localProductMap);
      }));
    } catch (error) {
      this.changedProductsWhichAreAlreadyInOrdersInDelivered.length = 0;
      this.changedProductsWhichAreAlreadyInOrdersInTransit.length = 0;
    }
  }

  async updateIfProductsHasAlreadyInOrder(order:any, productIds:any, localProductMap:any): Promise<void> {
    await Promise.all(order.get('products').map(async (product: any) => {
      await this.conn.fetchObject(product, 'title');
      if (!productIds.includes(product.get('margId'))) {
        order.get('products').splice(order.get('products').indexOf(product), 1);
      } else if (!localProductMap.has(product.get('margId'))) {
        localProductMap.set(product.get('margId'), {});
        if (order.get('stage') === 'DELIVERED') {
          this.changedProductsWhichAreAlreadyInOrdersInDelivered.push(product);
        } else {
          this.changedProductsWhichAreAlreadyInOrdersInTransit.push(product);
        }
      }
    }));
  }

  async isWhenToUseHavingSameDay(): Promise<any> {
    const productsInRegimen: Array<any> = this.regimenObj.get('morning').concat(this.regimenObj.get('night'));
    const map = {};
    const whenToApply = productsInRegimen
      .map((item: any) => {
        if (!item.instructionSet.frequencyOfApplicationLanguageString?.get('en')) return null;
        return item.instructionSet.frequencyOfApplicationLanguageString.get('en').split(',');
      })
      .filter((item: any) => item !== null)
      .filter((item: any) => item.includes('Monday') || item.includes('Tuesday'));
    const isDuplicate: boolean = whenToApply.some((item: any): boolean => item.some((i: any) => {
      const bool = !!map[i];
      map[i] = true;
      return bool;
    }));
    if (isDuplicate) return confirm('Two or more alternate day products have same days of application. are you sure?');
    return true;
  }

  async isRepairProductEditedButDisplayConcernNotChanged(): Promise<boolean> {
    if (!this.regimenChange?.originalProducts) return true;
    let isRepairProductsChanged = false;
    await Promise.all([
      ...this.regimenChange.originalProducts,
      ...this.regimenObj.get('morning'),
      ...this.regimenObj.get('night'),
    ].map((each: any) => this.conn.fetchObject(each.product.get('purposeLanguageString'), 'en')));
    const repairProductsBeforeEdit = this.regimenChange.originalProducts
      .filter((each: any): boolean => {
        const purpose = each.product.get('purpose') || each.product.get('purposeLanguageString')?.get('en');
        return purpose?.toLowerCase().includes('repair');
      }).map((each: any) => each.id);
    const repairProductsAfterEdit = [...this.regimenObj.get('morning'), ...this.regimenObj.get('night')]
      .filter((each: any): boolean => {
        const purpose = each.product.get('purpose') || each.product.get('purposeLanguageString')?.get('en');
        return purpose?.toLowerCase().includes('repair');
      }).map((each: any) => each.id);
    repairProductsBeforeEdit.forEach((each: any) => {
      if (!repairProductsAfterEdit.includes(each)) isRepairProductsChanged = true;
    });
    if ((isRepairProductsChanged || repairProductsAfterEdit.length !== repairProductsBeforeEdit.length)
      && this.isSameArrayOfParseObject(this.regimenObj.get('concernsLanguageString'), this.regimenChange.concernsLanguageString)) {
      return confirm('Are you sure to continue without changing display concern?');
    }
    return true;
  }

  async checkIfRegimenEditAllowed(): Promise<boolean> {
    if (this.regimenObj.get('type') !== ApiClientConstant.Regimen.Type.MAIN) return true;
    const alternateRegimens = JSON.parse(JSON.stringify(await ApiConnector.find(Table.Regimen, {
      where: {
        type: ApiClientConstant.Regimen.Type.ALTERNATE,
        active: true,
        regimenId: { $regex: `${this.regimenObj.get('regimenId')}`, $options: 'i' },
      },
      project: ['active', 'regimenId'],
    })));
    if (alternateRegimens?.length) {
      this.broadcast.broadcast('NOTIFY', { message: 'Active Alternate Regimen Found',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    const activeExperiment = await ApiConnector.findOne(Table.Experiment, {
      where: { key: `regimen_${this.regimenObj.get('regimenId')}`, enable: true },
      project: ['key'],
    });
    if (activeExperiment?.id) {
      this.broadcast.broadcast('NOTIFY', { message: 'Active Experiment Found For This Regimen',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    return true;
  }

  async repairShouldNotBeInMorning(): Promise<any> {
    const productsInRegimen: Array<any> = this.regimenObj.get('morning');
    const foundInMorning = productsInRegimen.find((each: any) => {
      if (!each.updated || !each.purposeLanguageString) return false;
      return each.purposeLanguageString.get('en')?.toLowerCase().includes('repair');
    });
    if (foundInMorning) {
      return confirm('Repair products found in morning section. Are you sure to continue?');
    }
    return true;
  }

  async showDialogForCreateOrderConfirmation(): Promise<any> {
    alert('New Products has been added, So needed to create new Order');
    this.regimenObj.set('paymentType', ApiClientConstant.Order.PaymentType.ONLINE);
    this.ui.makeBlur = true;
  }

  async saveRegimenCreatingOrder(placeOrderForNewProduct: boolean): Promise<any> {
    this.regimenObj.set('placeOrderForNewProduct', placeOrderForNewProduct);
    if (placeOrderForNewProduct && this.followUpProducts.length) {
      this.regimenObj.set('productIds', this.followUpProducts.map((each: any) => each.id));
    }
    this.ui.makeBlur = false;
    const context = { placeOrderForNewProduct };
    await this.saveRegimen(context);
  }

  stopPropagation(e: any): void {
    e.stopPropagation();
  }

  async copyRegimen(skipConfirmation?: boolean): Promise<any> {
    try {
      if (this.copyFromRegimenId.includes('personalized')) {
        await Promise.reject(new Error('Can\'t use personalized regimen to copy'));
      }
      if (this.copyFromRegimenId.split('_').length === 1) {
        this.copyFromRegimenId = `${this.regimenObj.get('regimenId').split('_')[0]}_${this.copyFromRegimenId}`;
      }
      const regimen = await this.conn.getRegimen(this.copyFromRegimenId, false, this.regimenObj.get('class'));
      if (!regimen) await Promise.reject(new Error('No Regimen Found'));
      if (!skipConfirmation && !confirm(`Are you sure to override regimen with ${regimen.get('regimenId')}`)) {
        return;
      }
      await this.copyFromRegimen(regimen);
    } catch (err) {
      alert(err.message || err);
    }
  }

  async copyFromRegimen(regimen: any, skipTimeout?: boolean): Promise<void> {
    const fieldsToSkip = [
      'regimenId',
      'class',
      'type',
      'objectId',
      'ACL',
      'endDate',
      'activeFromDate',
      'createdAt',
      'updatedAt',
      'active',
      'followUpConfig',
      'numberOfProductsLimit',
    ];
    if (this.regimenObj.has('forUser')) {
      fieldsToSkip.push('fixedPrice', 'fixedPriceMRP', 'invoiceTitle');
    }
    [].concat(Object.keys(JSON.parse(JSON.stringify(this.regimenObj))))
      .concat(Object.keys(JSON.parse(JSON.stringify(regimen))))
      .filter((key: string) => !fieldsToSkip.includes(key))
      .forEach((key: string) => this.regimenObj.set(key, regimen.get(key)));
    if (skipTimeout) return;
    const currentRegimen = this.regimenObj;
    this.regimenObj = new Table.Regimen();
    setTimeout(() => {
      this.regimenObj = currentRegimen;
      this.updatePrice();
    }, 0);
  }

  private fetchDietAndLifestyles(): void {
    this.conn.fetchDietAndLifestyles()
      .then((items: Array<any>) => (this.dietAndLifestyleList = items.map((item: any) => ({
        value: item.id,
        parseValue: item,
        display: item.get('titleLanguageString').get('en'),
      }))));
  }

  private removeUpdatedFlag(): void {
    const products = []
      .concat(...this.regimenObj.get('morning'))
      .concat(...this.regimenObj.get('night'));
    products.forEach((each_: any) => {
      const each = each_;
      delete each.updated;
    });
  }

  openRegimenProductChangeHistory(): void {
    this.dialog.open(RegimenProductChangeHistoryComponent, {
      width: '100%',
      data: { regimen: this.regimenObj },
    });
  }

  // variant changes
  copyRegimenFrom(copyTo_: any, copyFrom: any, updateDefaultValue: boolean = false): void {
    const copyTo = copyTo_;
    const copyFromRegimen = JSON.parse(JSON.stringify(copyFrom));
    Object.keys(copyFromRegimen).forEach((each: string) => {
      copyTo.set(each, copyFrom.get(each));
    });
    if (!updateDefaultValue) return;
    copyTo.unset('fixedPriceMRP');
    copyTo.unset('followUpConfig');
    copyTo.unset('fixedPrice');
    copyTo.unset('numberOfProductsLimit');
    copyTo.unset('regimenCoverImage');
    copyTo.set('tags', []);
    copyTo.set('morning', []);
    copyTo.set('night', []);
    copyTo.set('products', []);
    copyTo.unset('approvalPercentage');
  }

  async updateVariantsDetails(): Promise<void> {
    if (this.regimenObj.get('type') === ApiClientConstant.Regimen.Type.PERSONALIZED || !this.regimenObj.id) {
      return;
    }
    this.regimenObjVariant1 = this.regimenObj;
    this.regimenObjVariant2 = new Table.Regimen();
    this.regimenObjVariant3 = new Table.Regimen();
    this.regimenObjVariant4 = new Table.Regimen();

    this.copyRegimenFrom(this.regimenObjVariant2, this.regimenObj, true);
    this.updateRegimenObjVariant(this.regimenObjVariant2, this.appConfig.Shared.Regimen.Variants.VariantId.variant_1999);
    this.copyRegimenFrom(this.regimenObjVariant3, this.regimenObj, true);
    this.updateRegimenObjVariant(this.regimenObjVariant3, this.appConfig.Shared.Regimen.Variants.VariantId.variant_4999);
    this.copyRegimenFrom(this.regimenObjVariant4, this.regimenObj, true);
    this.updateRegimenObjVariant(this.regimenObjVariant4, this.appConfig.Shared.Regimen.Variants.VariantId.variant_999);
    this.variants = this.regimenObj.get('variants')?.length ? this.regimenObj.get('variants') : this.variants;
  }

  updateRegimenObjVariant(regimenObjVariant: any, variantId: string): void {
    let variant = this.regimenObj.get('variants')?.find((each: any) => each.variantId === variantId);
    if (!variant) {
      variant = this.variants.find((each: any) => each.variantId === variantId);
    }
    regimenObjVariant.set('showAfterDays', variant.showAfterDays);
    regimenObjVariant.set('shouldDisableCOD', variant.shouldDisableCOD);
    regimenObjVariant.set('fixedPriceMRP', variant.fixedPriceMRP);
    regimenObjVariant.set('followUpConfig', variant.followUpConfig);
    regimenObjVariant.set('fixedPrice', variant.fixedPrice);
    regimenObjVariant.set('numberOfProductsLimit', variant.numberOfProductsLimit);
    regimenObjVariant.set('tags', variant.tags);
    regimenObjVariant.set('morning', variant.morning);
    regimenObjVariant.set('night', variant.night);
    regimenObjVariant.set('products', variant.products);
    regimenObjVariant.set('approvalPercentage', variant.approvalPercentage);
  }
  updateVariantsCombinedId(): void {
    this.variants.forEach((variant_: any) => {
      const variant = variant_;
      variant.combinedProductIds = this.getCombinedProductIds(variant.products);
    });
  }
  async changeVariant(variant: ValueOf<typeof this.appConfig.Shared.Regimen.Variants.VariantId>): Promise<void> {
    // await this.checkRegimenExistsWithTagName(this.regimenObj.get('tags'));
    if (this.currentVariant === variant) {
      this.regimenObj = this.regimenObjVariant1;
      this.currentVariant = undefined;
      return;
    }
    const variants2RegimenCoverImage = await this.getImageUrlforCombinedProduct(this.variants[1]?.combinedProductIds);
    this.regimenObjVariant2.set('regimenCoverImage', variants2RegimenCoverImage);
    const variants3RegimenCoverImage = await this.getImageUrlforCombinedProduct(this.variants[2]?.combinedProductIds);
    this.regimenObjVariant3.set('regimenCoverImage', variants3RegimenCoverImage);
    const variants4RegimenCoverImage = await this.getImageUrlforCombinedProduct(this.variants[3]?.combinedProductIds);
    this.regimenObjVariant3.set('regimenCoverImage', variants4RegimenCoverImage);
    this.currentVariant = variant;
    switch (this.currentVariant) {
      case this.appConfig.Shared.Regimen.Variants.VariantId.variant_1499: {
        this.regimenObj = this.regimenObjVariant1;
        this.followUpConfig = this.regimenObjVariant1.get('followUpConfig');
        break;
      }
      case this.appConfig.Shared.Regimen.Variants.VariantId.variant_1999: {
        this.regimenObj = this.regimenObjVariant2;
        this.followUpConfig = this.regimenObjVariant2.get('followUpConfig');
        break;
      }
      case this.appConfig.Shared.Regimen.Variants.VariantId.variant_4999: {
        this.regimenObj = this.regimenObjVariant3;
        this.followUpConfig = this.regimenObjVariant3.get('followUpConfig');
        break;
      }
      case this.appConfig.Shared.Regimen.Variants.VariantId.variant_999: {
        this.regimenObj = this.regimenObjVariant4;
        this.followUpConfig = this.regimenObjVariant4.get('followUpConfig');
        break;
      }
      default:
    }
    if (!this.followUpConfig) {
      this.followUpConfig = {
        intervalInDays: 28,
        durationInDays: 90,
      };
    }
    this.changeMorningProducts = !this.changeMorningProducts;
    this.changeNightProducts = !this.changeNightProducts;
    const currentVariant = this.variants.find((each: any) => each.variantId === this.currentVariant);
    this.disableVariant = currentVariant?.disable || false;
  }

  async checkRegimenExistsWithTagName(tags: any): Promise<boolean> {
    let result = true;
    if (!tags?.length) return result;
    if (![1999, 4999].includes(this.regimenObj.get('fixedPrice'))) return result;
    const names = tags.map((tag: any) => tag.get('name').toUpperCase());
    const regimens = await this.conn.findRegimens({ where: { regimenId: { $in: names } } });
    if (!regimens?.length) return result;
    if (regimens.length) {
      alert('multiple regimen tags added');
    }
    if (regimens[0].get('products').length !== 5) {
      result = confirm('tagged regimen doesn\'t contain 5 products are you sure to continue');
    }
    return result;
  }

  async updateVariants(): Promise<boolean> {
    if (this.regimenObjVariant1?.get('morning')?.length || this.regimenObjVariant1?.get('night')?.length) {
      this.regimenObj = this.regimenObjVariant1;
      delete this.currentVariant;
    }
    if (this.regimenObj.get('type') === ApiClientConstant.Regimen.Type.PERSONALIZED
      || !this.regimenObj.id
      || !this.variants.length) {
      return true;
    }
    this.updateVariantFromRegimen(this.appConfig.Shared.Regimen.Variants.VariantId.variant_1499, this.regimenObjVariant1);
    this.updateVariantFromRegimen(this.appConfig.Shared.Regimen.Variants.VariantId.variant_1999, this.regimenObjVariant2);
    this.updateVariantFromRegimen(this.appConfig.Shared.Regimen.Variants.VariantId.variant_4999, this.regimenObjVariant3);
    this.updateVariantFromRegimen(this.appConfig.Shared.Regimen.Variants.VariantId.variant_999, this.regimenObjVariant4);
    this.updateVariantsCombinedId();
    if (!this.variants[1].products?.length && !this.variants[2].products?.length && !this.variants[3].products?.length) {
      return true;
    }
    const isValidCombinedId = await this.checkVariantCombinedId();
    if (!isValidCombinedId) {
      this.broadcast.broadcast('NOTIFY', { message: 'Not a valid CombinedId',
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    const filteredVariants = this.variants.filter((variant:any) => variant.products?.length);
    this.regimenObj.set('variants', filteredVariants);
    return true;
  }

  async checkVariantCombinedId(): Promise<boolean> {
    const variantsCombinedIds = await this.conn.findMediaLink({
      where: {
        uniqueId: this.variants.map((each: any) => each.combinedProductIds),
      },
    });
    if (!variantsCombinedIds.length) {
      return false;
    }
    const variantsCombinedObjectIds = variantsCombinedIds.map((each: any) => each.get('uniqueId'));
    const missingCombinedIds = [];
    this.variants.forEach((each: any) => {
      if (!variantsCombinedObjectIds.includes(each.combinedProductIds) && each.combinedProductIds) {
        missingCombinedIds.push(each.variantId);
      }
    });
    if (missingCombinedIds.length) {
      this.broadcast.broadcast('NOTIFY', { message: `combinedProductIds not found ${missingCombinedIds}`,
        type: this.appConfig.Shared.Toast.Type.ERROR });
      return false;
    }
    return true;
  }

  updateVariantFromRegimen(variantId: string, regimen: any): void {
    if (!regimen || !regimen.get('morning')?.length) return;
    let index = this.variants.findIndex((variant: any) => variant.variantId === variantId);
    if (index === -1) {
      this.variants.push({ variantId });
      index = this.variants.findIndex((variant: any) => variant.variantId === variantId);
    }
    this.variants[index].fixedPriceMRP = regimen.get('fixedPriceMRP');
    this.variants[index].followUpConfig = regimen.get('followUpConfig');
    this.variants[index].fixedPrice = regimen.get('fixedPrice');
    this.variants[index].combinedProductIds = regimen.get('combinedProductIds');
    this.variants[index].numberOfProductsLimit = regimen.get('numberOfProductsLimit');
    this.variants[index].tags = regimen.get('tags');
    this.variants[index].morning = regimen.get('morning');
    this.variants[index].night = regimen.get('night');
    this.variants[index].approvalPercentage = Number(regimen.get('approvalPercentage'));
    this.variants[index].products = this.getAllUniqueProducts([...regimen.get('morning'), ...regimen.get('night')]);
  }

  updateVariantFollowUpConfig(): void {
    switch (this.currentVariant) {
      case this.appConfig.Shared.Regimen.Variants.VariantId.variant_1499: {
        this.regimenObjVariant1.set('followUpConfig', this.followUpConfig);
        break;
      }
      case this.appConfig.Shared.Regimen.Variants.VariantId.variant_1999: {
        this.regimenObjVariant2.set('followUpConfig', this.followUpConfig);
        break;
      }
      case this.appConfig.Shared.Regimen.Variants.VariantId.variant_4999: {
        this.regimenObjVariant3.set('followUpConfig', this.followUpConfig);
        break;
      }
      case this.appConfig.Shared.Regimen.Variants.VariantId.variant_999: {
        this.regimenObjVariant4.set('followUpConfig', this.followUpConfig);
        break;
      }
      default:
    }
  }

  copyRegimenFromRegimen(regimenVariantFrom: any): void {
    const regimenVariantTo = this.currentVariant === 'variant2' ? 'regimenObjVariant2' : 'regimenObjVariant3';
    const morningProducts = regimenVariantFrom.get('morning').map((morningProduct: any) => ({ ...morningProduct }));
    const nightProducts = regimenVariantFrom.get('night').map((nightProduct: any) => ({ ...nightProduct }));
    this.regimenObj.set('morning', morningProducts);
    this.regimenObj.set('night', nightProducts);
    this.changeMorningProducts = !this.changeMorningProducts;
    this.changeNightProducts = !this.changeNightProducts;
  }

  getAllUniqueProducts(products: Array<any> = []): Array<any> {
    if (!products.length) return [];
    const addedProductsIds = [];
    const uniqueProducts = [];
    products.forEach((product: any) => {
      if (!addedProductsIds.includes(product.product.id)) {
        addedProductsIds.push(product.product.id);
        uniqueProducts.push(product.product);
      }
    });
    return uniqueProducts;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    delete this.router;
    delete this.appConfig;
    delete this.conn;
    delete this.route;
    delete this.subscriptions;
    delete this.regimenObj;
  }

  private isSameArrayOfParseObject(parseObjectArray1: Array<any> = [], parseObjectArray2: Array<any> = []): boolean {
    if (parseObjectArray1.length !== parseObjectArray2.length) {
      return false;
    }
    return parseObjectArray1.every((item: any, index: number) => (item.id === parseObjectArray2[index].id));
  }

  updateVariantIsDisable(): void {
    const index = this.variants
      .findIndex((variant: any) => variant.variantId === this.currentVariant);
    if (index === -1) return;
    this.variants[index].disable = this.disableVariant;
  }

  deleteFollowUp(index: any): any {
    this.followUpSop.splice(index, 1);
  }

  changeSelectedChatType(selectChat: boolean): void {
    if (selectChat) {
      this.selectedChatType = 'userChat';
      return;
    }
    this.selectedChatType = 'chatSummary';
  }

  markOrderWaitingForImageNew(): void {
    this.broadcast.broadcast('NOTIFY', { message: 'Can\'t be marked as waiting for image',
      type: this.appConfig.Shared.Toast.Type.ERROR });
  }

  private getConfirmationMessage(productsHasChanged: boolean): string {
    const confirmationMessage1 = 'Products are updated, do you want to approve regimen?';
    const confirmationMessage2 = 'No products are updated, do you want to approve regimen?';
    return productsHasChanged ? confirmationMessage1 : confirmationMessage2;
  }

  private async saveRegimenDrVerifiedState(): Promise<void> {
    try {
      this.regimenObj.set('doctorVerified', true);
      await this.regimenObj.save({}, { context: { placeOrderForNewProduct: false } });
      this.broadcast.broadcast('NOTIFY', { message: 'Regimen Updated', type: this.appConfig.Shared.Toast.Type.SUCCESS });
    } catch (err) {
      this.broadcast.broadcast('NOTIFY', {
        message: err.toString(),
        type: this.appConfig.Shared.Toast.Type.ERROR,
      });
    }
  }

  async approveRegimen(): Promise<void> {
    const productsHasChanged = this.regimenObj.dirtyKeys()?.length !== 0;
    const confirmationMessage = this.getConfirmationMessage(productsHasChanged);

    if (confirm(confirmationMessage)) {
      await this.saveRegimenDrVerifiedState();
    }
  }
  protected readonly apiClientConstant: typeof ApiClientConstant = ApiClientConstant;
}
